/* eslint-disable react-hooks/exhaustive-deps */
import { h, Fragment } from 'preact';
import { useCallback, useMemo } from 'preact/hooks';
import { isMobile } from 'react-device-detect';
import { Beforeunload } from 'react-beforeunload';
import { useInject } from 'inversify-hooks';

//Components
import Loader from '../../components/Loader/Loader';
import GameWindow from '../../components/GameWindow/GameWindow';
import Maintenance from '../../components/GameWindow/components/Maintenance/Maintenance';

import { useExit } from '../../common/hooks/use-exit/use-exit';

//Helpers
import { getAWCookies, getCookieValue, removeWrapperAppConfig } from '../../common/helpers/cookies';
import { useIsHiddenGame } from '../../common/hooks/useIsHiddenGame';

//Constants
import { DI_SERVICE } from '../../dependency-injection/constants';
import { HomeConfiguration } from './HomeConfiguration';
import { useQueryParameterValidation } from '../../common/hooks/use-query-parameter-validation';
import { useSelector } from 'react-redux';
import { COOKIES, GAME_CONTEXT } from '../../common/constants';
//Selectors
import { overallStatusSelector, providerSelector } from '../../selectors/gameContext';
import { customerDataSelector } from '../../selectors/customer-data';
import { gameInfoSelector } from '../../selectors/game-window';
import { GameWindowProvider } from '../../contexts/GameWindowContext';

//Styles
require(`./styles/${process.env.APPLICATION}/style.scss`);

export const pathName = '/';

const { authToken } = getAWCookies();
const Home = props => {
    const gameUid = props?.gameUid;
    const [WindowTitle] = useInject(DI_SERVICE.windowTitle);
    const exitGame = useExit();

    const gameProvider = useSelector(providerSelector);
    const overallStatus = useSelector(overallStatusSelector);
    const customerFeatures = useSelector(customerDataSelector);
    const gameInfo = useSelector(gameInfoSelector);

    const isGameValid = useQueryParameterValidation(gameUid)?.();
    const isValid = useMemo(() => !!(isGameValid && authToken), [isGameValid]);
    const isHiddenGame = useIsHiddenGame(customerFeatures, gameInfo);

    const shouldShowGame = useMemo(() => {
        const shouldShowAllRegions = getCookieValue(COOKIES.X_SHOW_ALL_REGIONS_TOKEN);
        return (
            !isHiddenGame &&
            (shouldShowAllRegions ||
                overallStatus === GAME_CONTEXT.OVERALL_STATUS.ACTIVE ||
                overallStatus === GAME_CONTEXT.OVERALL_STATUS.ACTIVE_BUT_NOT_DISPLAYED ||
                overallStatus === GAME_CONTEXT.OVERALL_STATUS.ACTIVE_BUT_BETTING_DISABLED ||
                overallStatus === undefined)
        );
    }, [overallStatus, isHiddenGame]);

    const renderByOverallStatus = useCallback(() => {
        if (shouldShowGame) {
            return (
                <div className={`home ${isMobile ? 'mobile' : 'desktop'}`}>
                    <div className="content-wrapper">
                        <div className="content-wrapper-grow">
                            <Loader />
                            {isValid && gameProvider && (
                                <Beforeunload onBeforeunload={removeWrapperAppConfig}>
                                    <GameWindowProvider>
                                        <GameWindow gameProvider={gameProvider} />
                                    </GameWindowProvider>
                                </Beforeunload>
                            )}
                        </div>
                    </div>
                </div>
            );
        }
        return <Maintenance exitGame={exitGame} />;
    }, [shouldShowGame, isValid, gameProvider]);

    return (
        <Fragment>
            <WindowTitle />
            {renderByOverallStatus()}
            <HomeConfiguration gameUid={gameUid} isValid={isValid} overallStatus={overallStatus} />
        </Fragment>
    );
};

export default Home;
