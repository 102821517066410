import { h, createContext } from 'preact';
import { useOrientation } from '../common/hooks/useOrientation';
import { isGameWindowDisabled, isRunningInsideWrapper } from '../common/wrapper-bridge-mobile';
import { useGameClientProxy } from '../components/Launcher/GameClient/hooks/useGameClientProxy';
import { Dispatch, StateUpdater, useContext, useMemo, useState } from 'preact/hooks';
import { useSelector } from 'react-redux';
import { showGameWindowSelector } from '../selectors/game-window';
import useCheckEligibilityForGameWindow from '../common/helpers/game-window-eligibility';
import {
    swjEnabledSelector,
    swjExperimentEnabledSelector,
    swjFeatureFlagSelector
} from '../selectors/site-wide-jackpot';
import { IContextShowoff } from '../common/hooks/use-showoff/helpers/showoff.types';

interface IGameWindowContext {
    deviceType: string;
    isMobile: boolean;
    isPortrait: boolean;
    reloadBalance: (payload: string) => void;
    showGameWindow: boolean;
    toggleAudio: (value: boolean) => void,
    toggleHelp: () => void,
    togglePaytable: () => void,
    dismissErrorMessage: () => void;
    exitGame: (redirectUrl: string) => void;
    showoffParams: IContextShowoff & { slideTo: (index: number) => void };
    setShowoffParams: Dispatch<StateUpdater<IContextShowoff & { slideTo: (index: number) => void }>>;
    disableGameWindow: boolean;
    chooseDialog: (payload?: number) => void;
    swjParameter?: string;
    isSwjEnabled: boolean;
}

export const GameWindowContext = createContext<IGameWindowContext>({} as IGameWindowContext);

export const GameWindowProvider = ({ children }) => {
    const { isPortrait, deviceType } = useOrientation();
    const isAndroidWrapper = isRunningInsideWrapper();

    const showGameWindowState = useSelector(showGameWindowSelector);
    const isEligible = useCheckEligibilityForGameWindow();

    const [showoffParams, setShowoffParams] = useState({} as IGameWindowContext['showoffParams']);
    const showGameWindow = useMemo(() => showGameWindowState || isEligible, [showGameWindowState, isEligible]);
    const disableGameWindow = isGameWindowDisabled();

    const swjToggle = useSelector(swjEnabledSelector);
    const swjEnabledExperiment = useSelector(swjExperimentEnabledSelector);
    const swjFeatureFlag = useSelector(swjFeatureFlagSelector);

    const url = new URL(window.location.href);
    const jackpotModeParameter = url.searchParams.get('jackpotMode');
    const swjParameter = jackpotModeParameter || (
        swjToggle && (swjFeatureFlag || swjEnabledExperiment) ? 'true' : 'false'
    );

    const {
        toggleHelp,
        togglePaytable,
        toggleAudio,
        reloadBalance,
        dismissErrorMessage,
        exitGame,
        chooseDialog,
    } = useGameClientProxy(true);

    return (
        <GameWindowContext.Provider
            value={{
                deviceType,
                isMobile: isAndroidWrapper,
                isPortrait,
                reloadBalance,
                showGameWindow,
                toggleAudio,
                toggleHelp,
                togglePaytable,
                dismissErrorMessage,
                exitGame,
                showoffParams,
                setShowoffParams,
                disableGameWindow,
                chooseDialog,
                swjParameter,
                isSwjEnabled: swjParameter === 'true'
            }}
        >
            {children}
        </GameWindowContext.Provider>
    )
}

export const useGameWindowContext = () => useContext(GameWindowContext);
