/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'preact/hooks';
import { APPTENTIVE_EVENTS } from '../../common/constants/game-constants';
import { useDispatch, useSelector } from 'react-redux';
import { useInject } from 'inversify-hooks';
import { DI_SERVICE } from '../../dependency-injection/constants';
import { getUserContext } from '../../redux/actions/user-context';
import { getRegion } from '../../common/helpers/cookies';
import { AW_COOKIE, GAME_CONTEXT, RGIS, TIMERS_TIMESTAMPS } from '../../common/constants';
import { getAmplitudeKey, MODULES, ZONES } from '../../common/hooks/use-user-tracking/types';
import { useLogTracking } from '../../common/hooks/use-user-tracking/useUserTracking';
import { getTimezone } from 'casino-fabric-l10n/lib/localization-helper';
import config from '../../../config/config';
import { getQueryParameter } from '../../common/helpers/queryparams';
import Cookie from 'js-cookie';
import { isGameWindowDisabled } from '../../common/wrapper-bridge-mobile';
import { fetchGameWindow } from '../../redux/actions/game-window/fetch-game-window';
import { setGameClientHandshake } from '../../redux/actions/gameClientStatus';
import { setGameContext } from '../../redux/actions/gameContext';
import { Fragment } from 'preact';
import { useLogGameLaunched } from '../../common/hooks/useLogGameLaunched';
import { useAuthenticated } from '../../common/hooks';
import useMessageHandler from '../../common/hooks/use-message-handler';
import { useUserTrackingSetup } from '../../common/hooks/use-user-tracking/useUserTrackingSetup';
import useRemoveInitialLoader from '../../common/hooks/use-remove-initial-loader/use-remove-initial-loader';
import { useCurrentTime } from '../../common/hooks/useCurrentTime';
import { useSessionTime } from '../../common/hooks/useSessionTime';
import { useGameTime } from '../../common/hooks/useGameTime';

const { SERVER_MARK } = TIMERS_TIMESTAMPS;

export const HomeConfiguration = ({gameUid, isValid, overallStatus}) => {
    const dispatch = useDispatch();
    const userId = useSelector(state => state?.session?.userId);
    const userSessionStartTime = useSelector(state => state?.session?.sessionStartTime);
    const timezone =
        useSelector(state => state?.session?.timezone) ||
        getTimezone(config?.countryCode, getQueryParameter('region') || Cookie.get(AW_COOKIE.REGION));

    const [getCurrentSessionDetails] = useInject(DI_SERVICE.getCurrentSessionDetails);
    const [getUserDetails] = useInject(DI_SERVICE.getUserDetails);
    const [getBalanceData] = useInject(DI_SERVICE.balanceData);
    const [sendToApptentive] = useInject(DI_SERVICE.sendToApptentive);
    const [getServerTime] = useInject(DI_SERVICE.getServerTime);
    const [useGeocomplyFlow] = useInject(DI_SERVICE.geocomplyFlow);

    const [tickerInitialized, setTickerInitialized] = useState(false);
    const [userTrackingInitialized, setUserTrackingInitialized] = useState(false);

    const { logTrackingComponent } = useLogTracking();
    useLogGameLaunched();
    useAuthenticated();
    useMessageHandler(dispatch);
    useUserTrackingSetup(userTrackingInitialized, () => setUserTrackingInitialized(true));
    useGeocomplyFlow();
    useRemoveInitialLoader();
    useCurrentTime(tickerInitialized);
    useSessionTime(tickerInitialized);
    useGameTime(tickerInitialized);

    const rgi = (getQueryParameter('RGI') ?? '').toLowerCase();

    const getServerTimeOnPageComeback = () => {
        if (document?.visibilityState === 'visible') {
            dispatch(getServerTime(timezone));
        }
    };

    useEffect(() => {
        if (isValid) {
            dispatch(getCurrentSessionDetails());
            dispatch(getUserDetails());
            dispatch(getUserContext());
            dispatch(getBalanceData(true));
        }
    }, [isValid]);

    useEffect(() => {
        if (userId) {
            sendToApptentive(APPTENTIVE_EVENTS.GAME_LAUNCHED, {
                product_account_id: userId,
                last_activity_date: userSessionStartTime
            });
        }
    }, [userId]);

    useEffect(() => {
        logTrackingComponent({
            [getAmplitudeKey('EVENT')]: 'Game Launch Initiated',
            [getAmplitudeKey('COMPONENT')]: 'log_game_launched',
            [getAmplitudeKey('MODULE')]: MODULES.GW,
            [getAmplitudeKey('ZONE_NAME')]: ZONES.IN_GAME
        });

        //we need to set a common domain in order to be allowed to close a window
        // that was opened by another app (with same domain)
        // so we strip the original domain of 'launcher'
        try {
            if (document.domain) {
                document.domain = document.domain.replace('launcher.', '');
            }
        } catch (err) {
            console.error(err);
        }
        dispatch(getServerTime(timezone, () => setTickerInitialized(true)));
        document.addEventListener('visibilitychange', getServerTimeOnPageComeback);
        return () => {
            window?.performance?.clearMarks?.(SERVER_MARK);
            // window?.performance?.clearMarks?.(GAME_MARK);
            // TODO: find another fix for the issue with the game timer
            document.removeEventListener('visibilitychange', getServerTimeOnPageComeback);
        };
    }, [timezone]);

    useEffect(() => {
        if (!isGameWindowDisabled()) {
            if (gameUid) {
                dispatch(fetchGameWindow(gameUid));
            } else {
                dispatch(fetchGameWindow());
            }
        } else if (getQueryParameter('nativeId') === '-1') {
            //aggregation call on fake game for SWJ data
            dispatch(fetchGameWindow(gameUid));
        }
    }, [dispatch]);

    useEffect(() => {
        const shouldPerformDirectHandshakeIOS = getQueryParameter('handshake');
        if (shouldPerformDirectHandshakeIOS) {
            dispatch(setGameClientHandshake(shouldPerformDirectHandshakeIOS === 'on'));
        }

        if (gameUid === undefined) {
            dispatch(
                setGameContext({
                    brand: getQueryParameter('brand'),
                    gameId: getQueryParameter('gameId'),
                    gameName: getQueryParameter('gameName'),
                    ganId: getQueryParameter('ganId'),
                    provider: getQueryParameter('providerName'),
                    providerRef: getQueryParameter('providerRef'),
                    region: getRegion(),
                    rgi,
                    overallStatus: rgi === RGIS.INCENTIVE_GAMES ? GAME_CONTEXT.OVERALL_STATUS.ACTIVE : overallStatus,
                    gameUid: getQueryParameter('gameUid')
                })
            );
            if (getQueryParameter('nativeId') === '-1') {
                dispatch(
                    setGameContext({
                        nativeId: getQueryParameter('nativeId'),
                        gameLoaded: true
                    })
                );
            }
        }
    }, [dispatch]);

    return <Fragment />;
}
