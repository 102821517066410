import { h } from 'preact';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import WarningIcon from '../../../assets/icons/GameWindow/WarningIcon';
import XButton from '../../../assets/icons/GameWindow/XButton';
import { hideExitPopup } from '../../../redux/actions/exit-popup';
import { dismissLauncher } from '../../../common/helpers/redirect-flows';
import { gameWindowSWJLatestTiers, gameWindowSWJOptIn, responsibleGamingLinkSelector } from '../../../selectors/game-window';

import { useCallback, useEffect, useMemo } from 'preact/hooks';
import { useLogTracking } from '../../../common/hooks/use-user-tracking/useUserTracking';
import { MODULES, ZONES, getAmplitudeKey } from '../../../common/hooks/use-user-tracking/types';
import { getAmplitudeSWJTiersParameters } from '../../../common/helpers/swjHelpers';

const ExitPopup = () => {
    require(`./styles/${process.env.APPLICATION}/style.scss`);

    const dispatch = useDispatch();
    const { logTrackingComponent } = useLogTracking();

    const { t } = useTranslation();



    const exitGameMessage = `${t('gameWindow.exitPopup.message')}`;
    const exitButton = `${t('gameWindow.exitPopup.buttonExit')}`;
    const cancelButton = `${t('gameWindow.exitPopup.buttonCancel')}`;
    const displayExitPopup = useSelector(state => state?.exitPopup?.displayModal);
    const redirectMsg = useSelector(state => state?.exitPopup?.redirectMsg);
    const responsibleGamingLink = useSelector(responsibleGamingLinkSelector);
    const isMenuOpen = useSelector(state => state?.displayMenu?.displayMenu);
    const currentZone = isMenuOpen ? ZONES.IN_GAME : ZONES.DASHBOARD;
    const jackpotTiers = useSelector(gameWindowSWJLatestTiers);
    const optIn = useSelector(gameWindowSWJOptIn);

    const sendGtmMessage = useCallback(() => {
        logTrackingComponent({
            [getAmplitudeKey('EVENT')]: 'In Game Exit Modal Viewed',
            [getAmplitudeKey('MODULE')]: MODULES.GW,
            [getAmplitudeKey('ZONE_NAME')]: currentZone
        });
    }, [logTrackingComponent]);

    useEffect(() => {
        if (displayExitPopup) {
            sendGtmMessage();
        }
    }, [displayExitPopup, sendGtmMessage]);

    const commonPayload = useMemo(() => ({
        [getAmplitudeKey('MODULE')]: 'game_window_exit_modal',
        [getAmplitudeKey('ZONE_NAME')]: currentZone
    }),[currentZone]);

    const handleExitIcon = useCallback(() => {
        logTrackingComponent({
            ...commonPayload,
            [getAmplitudeKey('EVENT')]: 'In Game Exit Modal Closed',
            [getAmplitudeKey('LINK_URL')]: undefined,
            [getAmplitudeKey('LINK_TEXT')]: exitButton
        });
        dispatch(hideExitPopup());
    }, [commonPayload, dispatch, exitButton, logTrackingComponent]);

    const handleExitButton = useCallback(() => {
        logTrackingComponent({
            ...commonPayload,
            [getAmplitudeKey('EVENT')]: 'In Game CTA Clicked',
            [getAmplitudeKey('LINK_URL')]: redirectMsg ? responsibleGamingLink : undefined,
            [getAmplitudeKey('LINK_TEXT')]: exitButton,
            [getAmplitudeKey('FDC_JACKPOT_OPT_IN_STATUS')]: optIn > 0 ? 'opted_in' : 'opted_out',
            ...getAmplitudeSWJTiersParameters(jackpotTiers)
        });
        dismissLauncher(redirectMsg, responsibleGamingLink);
    }, [commonPayload, exitButton, logTrackingComponent, redirectMsg, responsibleGamingLink, jackpotTiers]);

    const handleCancelButton = useCallback(() => {
        logTrackingComponent({
            ...commonPayload,
            [getAmplitudeKey('EVENT')]: 'In Game CTA Clicked',
            [getAmplitudeKey('LINK_URL')]: undefined,
            [getAmplitudeKey('LINK_TEXT')]: cancelButton,
            [getAmplitudeKey('FDC_JACKPOT_OPT_IN_STATUS')]: optIn > 0 ? 'opted_in' : 'opted_out',
            ...getAmplitudeSWJTiersParameters(jackpotTiers)
        });
        dispatch(hideExitPopup());
    }, [cancelButton, commonPayload, dispatch, logTrackingComponent, jackpotTiers]);

    return displayExitPopup ? (
        <div className="games-modal general exit-popup">
            <div className="games-container container exit-popup">
                <button className="exit-icon">
                    <div onClick={handleExitIcon}>
                        <XButton color={'gray'} size={'25'} strokeWidth={'2.59'} />
                    </div>
                </button>
                <div className="exit-body">
                    <div className="exit-header">
                        <WarningIcon />
                        <p className="exit-title">{exitGameMessage}</p>
                    </div>
                </div>
                <div className="exit-footer">
                    <button className="exit-button primary" onClick={handleExitButton}>
                        {exitButton}
                    </button>
                    <button className="exit-button second" onClick={handleCancelButton}>
                        {cancelButton}
                    </button>
                </div>
            </div>
        </div>
    ) : null;
};

export default ExitPopup;
