import TermsIcon from '../../../../../assets/icons/GameWindow/Terms';
import { getIconSize } from '../../../../../common/helpers/get-icon-size';
import { useSelector } from 'react-redux';
import {
    gameWindowSWJLatestTiers,
    gameWindowSWJOptIn,
    responsibleGamingLinkSelector
} from '../../../../../selectors/game-window';

import { openExternalWebview } from '../../../../../common/helpers/open-external-webview';
import { useCallback } from 'preact/hooks';
import { useLogTracking } from '../../../../../common/hooks/use-user-tracking/useUserTracking';
import { AMPLITUDE_NAVIGATION_EVENTS, MODULES, ZONES, getAmplitudeKey } from '../../../../../common/hooks/use-user-tracking/types';
import { getAmplitudeSWJTiersParameters } from '../../../../../common/helpers/swjHelpers';

export const ResponsibleGaming = ({ deviceType }) => {
    const { logTrackingComponent } = useLogTracking();
    const responsibleGamingUrl = useSelector(responsibleGamingLinkSelector);
    const optIn = useSelector(gameWindowSWJOptIn);
    const jackpotTiers = useSelector(gameWindowSWJLatestTiers);

    const handleResponsibleGaming = useCallback(() => {
        logTrackingComponent({
            [getAmplitudeKey('EVENT')]: AMPLITUDE_NAVIGATION_EVENTS.NAVIGATION_LINK_CLICKED,
            [getAmplitudeKey('MODULE')]: MODULES.IN_GAME_FOOTER,
            [getAmplitudeKey('LINK_URL')]: responsibleGamingUrl,
            [getAmplitudeKey('LINK_TEXT')]: 'responsible_gaming',
            [getAmplitudeKey('ZONE_NAME')]: ZONES.GAME_HELP,
            [getAmplitudeKey('FDC_JACKPOT_OPT_IN_STATUS')]: optIn > 0 ? 'opted_in' : 'opted_out',
            ...getAmplitudeSWJTiersParameters(jackpotTiers)
        });
        openExternalWebview(responsibleGamingUrl);
    }, [logTrackingComponent, responsibleGamingUrl]);

    return (
        <a
            className="terms-icon"
            href={responsibleGamingUrl}
            target="_blank"
            rel="noreferrer"
            onClick={handleResponsibleGaming}
        >
            <TermsIcon height={getIconSize(deviceType)} />
        </a>
    );
};
