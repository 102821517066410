import { JackpotTier } from '../../../components/GameWindow/components/SWJ/TopBar/jackpotTiers';

export const setAudioEnabled = (payload: boolean): SetAudioEnabled => ({ payload, type: 'SET_AUDIO_ENABLED' });

export const setInGameBalance = (payload: number): SetInGameBalance => ({ payload, type: 'SET_IN_GAME_BALANCE' });

export const setStake = (payload: number): SetStake => ({ payload, type: 'SET_STAKE' });

export const setWin = (payload: number): SetWin => ({ payload, type: 'SET_WIN' });

export const setSpinInProgress = (payload: boolean): SetSpinInProgress => ({ payload, type: 'SET_SPIN_IN_PROGRESS' });

export const setAudioSettingsReceived = (payload: boolean): SetAudioSettingsReceived => ({
    payload,
    type: 'SET_AUDIO_SETTINGS_RECEIVED'
});

export const setSessionTimeAction = (payload: string): SetSessionTime => ({ payload, type: 'SET_SESSION_TIME' });

export const setCurrentTimeAction = (payload: string): SetCurrentTime => ({ payload, type: 'SET_CURRENT_TIME' });

export const setGameTimeAction = (payload: string): SetGameTime => ({ payload, type: 'SET_GAME_TIME' });

export const setLicenseCardVisible = (payload: boolean): SetLicenseCardVisible => ({
    payload,
    type: 'SET_LICENSE_CARD_VISIBLE'
});

export const setDismissErrorMessage = (): SetDismissErrorMessage => ({
    type: 'SET_DISMISS_IN_GAME_ERROR'
});

export const setInGameError = (payload: {
    errorCode: string;
    errorMessage: string;
    errorType: string;
    errorParameters?: Record<string, unknown>;
}): SetInGameError => ({
    payload,
    type: 'SET_IN_GAME_ERROR'
});

export const setGameLoadComplete = (payload: boolean): SetGameLoadComplete => ({
    payload,
    type: 'GAME_LOAD_COMPLETE'
});

export const setGameWindowExpansionState = (payload: boolean): SetGameWindowExpansionState => ({
    payload,
    type: 'GAME_WINDOW_EXPANSION_STATE'
});

export const setGameWindowSWJLatestTiers = (payload: Array<JackpotTier>): SetGameWindowSWJLatestTiers => ({
    payload,
    type: 'SET_SWJ_LATEST_TIERS'
});

export const setGameWindowSWJOptIn = (payload: undefined | number): SetGameWindowSWJOptIn => ({
    payload,
    type: 'SET_SWJ_OPT_IN'
});

export const setGameExpansionToggleDisplayState = (payload: boolean): SetGameExpansionToggleDisplayState => ({
    payload,
    type: 'GAME_EXPANSION_TOGGLE_DISPLAY_STATE'
});


export const setGameWindowExpansionDisabled = (payload: boolean): SetGameWindowExpansionDisabled => ({
    payload,
    type: 'GAME_WINDOW_EXPANSION_DISABLED'
});

export const setLoadingPercent = (payload: number): SetLoadingPercent => ({
    payload,
    type: 'SET_LOADING_PERCENT'
});

interface SetAudioEnabled {
    type: 'SET_AUDIO_ENABLED';
    payload: boolean;
}

interface SetInGameBalance {
    type: 'SET_IN_GAME_BALANCE';
    payload: number;
}

interface SetStake {
    type: 'SET_STAKE';
    payload: number;
}

interface SetWin {
    type: 'SET_WIN';
    payload: number;
}

interface SetSpinInProgress {
    type: 'SET_SPIN_IN_PROGRESS';
    payload: boolean;
}

interface SetAudioSettingsReceived {
    type: 'SET_AUDIO_SETTINGS_RECEIVED';
    payload: boolean;
}

interface SetGameTime {
    type: 'SET_GAME_TIME';
    payload: string;
}

interface SetCurrentTime {
    type: 'SET_CURRENT_TIME';
    payload: string;
}

interface SetSessionTime {
    type: 'SET_SESSION_TIME';
    payload: string;
}

interface SetLicenseCardVisible {
    type: 'SET_LICENSE_CARD_VISIBLE';
    payload: boolean;
}

interface SetDismissErrorMessage {
    type: 'SET_DISMISS_IN_GAME_ERROR';
}

interface SetInGameError {
    type: 'SET_IN_GAME_ERROR';
    payload: { errorCode: string; errorMessage: string; errorType: string };
}

interface SetGameLoadComplete {
    type: 'GAME_LOAD_COMPLETE';
    payload: boolean;
}

interface SetGameWindowSWJLatestTiers {
    type: 'SET_SWJ_LATEST_TIERS';
    payload: Array<JackpotTier>;
}

interface SetGameWindowSWJOptIn {
    type: 'SET_SWJ_OPT_IN';
    payload: undefined | number;
}

interface SetGameWindowExpansionState {
    type: 'GAME_WINDOW_EXPANSION_STATE';
    payload: boolean;
}

interface SetGameExpansionToggleDisplayState {
    type: 'GAME_EXPANSION_TOGGLE_DISPLAY_STATE';
    payload: boolean;
}

interface SetGameWindowExpansionDisabled {
    type: 'GAME_WINDOW_EXPANSION_DISABLED';
    payload: boolean;
}


interface SetLoadingPercent {
    type: 'SET_LOADING_PERCENT';
    payload: number;
}
